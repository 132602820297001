import { systemNoticeGet } from '@/api/project'
import Cookies from 'js-cookie'
import util from '@/utils/util'
import store from "@/store";

export default {
    data(){
        return {

        }
    },
    methods:{
        async systemNoticeGet(){
            let storeObj = this.$store;
            let itemByQuery = Cookies.get('itemByQuery'),
                strH = [271,150];
            itemByQuery = itemByQuery?JSON.parse(itemByQuery):{}
            if(!this.$store){
                storeObj = store;
            }
            await systemNoticeGet().then(res=>{
                    if(res && res.data){
                        let isShowType = this.getCookies('isShowType');
                        this.setCookies('systemMaintain',JSON.stringify(res.data),5*60)
                        storeObj.commit('setSystemMaintain',res.data);
                        if(!isShowType){
                            storeObj.commit('setPreserveType',1);
                        }else{
                            storeObj.commit('setPreserveType',isShowType);
                        }
                        if(res.data.type === 0 && !strH.includes(itemByQuery.id)){
                            util.toLogout();
                        }
                    }else{
                        this.removeCookies('systemMaintain');
                        this.removeCookies('isShowType');
                        storeObj.commit('setSystemMaintain', {});
                        storeObj.commit('setPreserveType','');
                    }
                })
        },
        getCookies (key) {
            return Cookies.get(key)
        },
        setCookies (key, value, expiresTime) {
            let seconds = expiresTime
            let expires = new Date(new Date() * 1 + seconds * 1000)
            let size = util.getRootDomain()?util.getRootDomain():'localhost';
            Cookies.set(key, value, { expires: expires,domain:size})
        },
        removeCookies (key) {
            let size = util.getRootDomain()?util.getRootDomain():'localhost';
            Cookies.remove(key,{domain:size})
        }
    }
}