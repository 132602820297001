import axios from '@/utils/ajax';
import Global from "@/utils/global";
import util from "@/utils/util"
let OSS = require('ali-oss');

class ossContainer{
    constructor() {
        this.client = null;
    }
    getAssumeRole(params){
        return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL2') + '/oss/getAssumeRole','get',params,true);
    }
    /**
     * 转换域名
     * @param {string} str1 母地址
     * @param {string} str2 替换成的地址
     * returns url
     *  */
    urlTransform(str1,str2){
        let url = '';
        if(str1.indexOf('https') == -1){
            url = str1.replace(/^http:\/\/[^/]+/, "");
        }else{
            url = str1.replace(/^https:\/\/[^/]+/, "");
        }
        return str2 + url;
    }
    /**
     * 上传文件，大小不能超过5GB
     * @param {string} objName OSS存储路径和文件名字
     * @param {string} fileUrl 本地文件
     * returns Promise
     *  */

    put = async (params,ObjName, fileUrl) =>{
        try {
            const res = await this.getAssumeRole(params);
            if(!res.data){
                util.errorMessage('获取oss权限失败，请重新请求');
                return;
            }
            let obj = JSON.parse(JSON.stringify(res.data));
            obj.timeout = 120000;
            this.client = new OSS(obj);
            let path = res.data && res.data.filePath.replace(res.data.bucket+'/','');
            return this.client.put(`${path}/${ObjName}`, fileUrl);

            //ObjName为文件名字，可以只写名字，就直接存储在bucket的根路径,如需放在文件夹下面直接在文件名前面加上文件夹名称
        } catch (e) {
            return e.message;
        }
    }

    async deleteOssList(picName){
        try {
            return this.client.delete(picName);
        } catch (e) {
            return e.message
        }
    }

    multipartUpload = async (params,ObjName, file,onUploadProgress,checkpoint) => {
        try {
            let res,path;
            if(!file.client && !checkpoint){ //判断是否已经有oss实列
                res = await this.getAssumeRole(params);
                if(!res.data){
                    util.errorMessage('获取oss权限失败，请重新请求');
                    return;
                }
                file.client = new OSS(res.data);
                path = res.data && res.data.filePath.replace(res.data.bucket+'/','');
                return file.client.multipartUpload(`${path}/${ObjName}`, file.raw, {
                    parallel: 4,
                    partSize: 1024 * 1024,
                    progress: async (p, checkpoint, res) => {
                        await onUploadProgress(file, p, checkpoint, res, file.name)
                    }
                })
            }else{
                return file.client.multipartUpload(checkpoint.uploadId, file.raw, {
                    parallel: 4,
                    partSize: 1024 * 1024,
                    progress: async (p, checkpoint, res) => {
                        await onUploadProgress(file, p, checkpoint, res, file.name)
                    },
                    checkpoint
                })
            }

            //ObjName为文件名字，可以只写名字，就直接存储在bucket的根路径,如需放在文件夹下面直接在文件名前面加上文件夹名称
            // return result
        } catch (e) {
            console.log(e)
        }
    }


    /**
     * 上传成功之后，转换真实地址
     * @param {string} objName文件名称
     * */
    signatureUrl = async (params,objName) => {
        try {
            // const res = await this.getAssumeRole(params);
            // this.client = new OSS(res.data);
            let result = await this.client.signatureUrl(`${objName}`)
            return result;
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * 生成随机文件名称（保证上传文件的唯一性）
     * 规则八位随机字符，加下划线连接时间戳
     * */
    getFileNameUUID = () => {
        function rx() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        }

        return `${+new Date()}_${rx()}${rx()}`
    }
}

export default ossContainer;