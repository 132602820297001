import Vue from 'vue'
import VueRouter from 'vue-router'
// import loginView from '@/views/login/index'
// import navigationBar from '@/views/navigationBar'
import Global from 'utils/global'
// import comm from 'utils/comm'
import Cookies from "js-cookie"
import axios from 'utils/ajax';
import util from  '@/utils/util'
import projectStatus from "@/utils/mixins/projectStatus";


Vue.use(VueRouter)

// let routerArr = [];


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/activity'
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/activity/index'),
    children: [
      {
        path: '/activity',
        name: 'activityActivityType',
        redirect: '/activity/Controller'
      },
      {
        path: '/activity/ActivityType',
        name: 'ActivityType',
        component: () => import('@/views/activity/ActivityType')
      },
      {
        path: '/activity/Controller/:id',
        name: 'Controller',
        component: () => import('@/views/activity/Controller')
      },
      {
        path: '/activity/ControllerEdit', //编辑活动----基本信息
        name: 'ControllerEdit',
        component: () => import('@/views/activity/basicsMessage/index.vue')
      },
      //活动环节
      {
        path: '/activity/transition',  //活动过渡环节
        name: 'transition',
        component: () => import('@/views/activity/Template/components/transition.vue')
      },
      {
        path: '/activity/task',  //任务提交
        name: 'task',
        component: () => import('@/views/activity/Template/components/task.vue')
      },
      {
        path: '/activity/interaction', //互动交流环节
        name: 'interaction',
        component: () => import('@/views/activity/Template/components/interaction.vue')
      },
      {
        path: '/activity/broadcast', //直播课堂
        name: 'broadcast',
        component: () => import('@/views/activity/Template/components/broadcast.vue')
      },
      {
        path: '/activity/curriculum', //课程学学习
        name: 'curriculum',
        component: () => import('@/views/activity/Template/components/curriculum.vue')
      },
      {
        path: '/activity/sign', //签到
        name: 'sign',
        component: () => import('@/views/activity/Template/components/sign.vue')
      },
      {
        path: '/activity/survey', //问卷
        name: 'survey',
        component: () => import('@/views/activity/Template/components/survey.vue')
      },
      {
        path: '/activity/exam', //考试
        name: 'exam',
        component: () => import('@/views/activity/Template/components/exam.vue')
      },
      {
        path: '/activity/Template', //创建活动 -- 使用模板
        name: 'Template',
        component: () => import('@/views/activity/basicsMessage')
      },
      {
        path: '/activity/customs', //创建活动---基本信息
        name: 'customs',
        component: () => import('@/views/activity/basicsMessage/index.vue')
      },
      //活动模版
      {
        path: '/activity/custset',
        name: 'custset',
        component: () => import('@/views/activity/Controller/custset.vue')
      },
      {
        path: '/activity/indexset',
        name: 'indexset',
        component: () => import('@/views/activity/Template/indexset.vue')
      },
      //模版环节
      {
        path: '/activity/taskTemplate',
        name: 'task2',
        component: () => import('@/views/activity/Template/componentset/task.vue')
      },
      {
        path: '/activity/interactionTemplate',
        name: 'interaction2',
        component: () => import('@/views/activity/Template/componentset/interaction.vue')
      },
      {
        path: '/activity/broadcastTemplate',
        name: 'broadcast2',
        component: () => import('@/views/activity/Template/componentset/broadcast.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,from,next)=>{
//    if(to.name === 'login'){
//       Global.clearToken();
//       next();
//    }else if(to.name !== 'login' && comm.isEmpty(Global.getToken())){
//      next({
//        name: 'login'// home page
//      });
//    }else if(to.name !== 'login' && !comm.isEmpty(Global.getToken())){
//      next();
//    }
// })

//根据token查询数据详细信息
function getUserInfoLogin(){
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL')+'/user/getLoginInfo','get');
}

router.beforeEach(async (to,from,next)=>{
  let access_token = Cookies.get('access_token')
  let vuexToken = router.app.$options.store.state.token,
      itemByQuery = Cookies.get('itemByQuery')?JSON.parse(Cookies.get('itemByQuery')): {},
      strH = [271,150],
      systemMaintain = Cookies.get('systemMaintain');

  if(!systemMaintain){
    await projectStatus.methods.systemNoticeGet();
  }

  if(util.isSystemStatus() && !strH.includes(itemByQuery.id)){
    util.toLogout(true);
    return;
  }

  //当前用户是否已经登录
  if(!vuexToken && !access_token){
    console.log(process.env)
    location.href = process.env.VUE_APP_LOGIN_URL;
    return;
  }
   if ((!vuexToken && access_token) || ((vuexToken && access_token) && vuexToken != access_token)) {  // 判断当前的token是否存在
       getUserInfoLogin().then(res=>{
           router.app.$options.store.commit('getUserInfo', res.data)
           console.log(res.data);
           next();
       })
   }else{
       next();
   }
})

export default router
