import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import global from '@/utils/global'
import Cookie from 'js-cookie'
import util from "@/utils/util";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:null,
    token:null,
    menuActiveItem:'1',
    activityEdit:null,
    next:[],
    dataStatu:null,
    dataStatusend:true,
    linkName:null,
    templateName:null,
    activityPartList:[],
    userImportFile: {},
    activeData:{},
    isFabu:false,
    systemMaintain:{}, //系统维护管理信息
    preserveType:'', //维护状态 1显示维护通知 2.缩小显示 3不显示
    isEditing:false, //是否有修改
  },
  getters: {
  },
  mutations: {
    setIsEditing(state,item){
      state.isEditing = item;
    },
    setSystemMaintain(state,item){
      state.systemMaintain = item;
    },
    setPreserveType(state,item){
      state.preserveType = Number(item);
      let size = util.getRootDomain()?util.getRootDomain():'localhost';
      Cookie.set('isShowType',item,{domain:size})
    },
    getUserInfo(state,data){
      state.userInfo = data;
      // console.log(data,"userinfo");
      state.token = data.token;
    },
    setActiveData(state,data){
      state.activeData = data;
    },
    setMenuActiveItem(state,data){
      state.menuActiveItem = data;
    },
    //跳转到详情页面
    toParticulars(state,item){
      let obj = Cookie.get('itemByQuery') && JSON.parse(Cookie.get('itemByQuery')),
      url = obj.communitySiteUrl || global.getParamsKey('XQ_URL');
      if(item.type=='GG'){
        window.open(url+'/home/'+item.data.id);
      }else if(item.type=="CHECK"){
        window.open(url+'/plan/detail');
      }else if(item.type=='HDMB'){
        window.open(url+'/basic/info/'+ item.id + '/' + 'false');
      }else if(item.type=='HD'){
        window.open(url+'/basic/info/'+ item.id);
      }else if(item.type=='HDYL'){ //活动预览页
        location.href = (url+'/basic/info/'+ item.id + '/1/true');
      }else{
        window.open(url+'/teaching/'+item.data.id);
      }
    },
    //保存活动页=>环节参数
    getactivityEdit(state,item){
      state.activityEdit = item
    },
    //保存活动页=>下一步环节
    nextStep(state,item){
      state.next = item
    },
    //保存之后取消所有的
    dataStatus(state,item){
      state.dataStatu = item
    },
    //发布活动是不是已经发布过的
    dataStatusends(state,item){
      state.dataStatusend = item
    },
    //活动名称
    linkMethod(state,item){
      state.linkName = item
    },
    templateMethod(state,item){
      state.templateName = item
    },
    //存储左侧环节菜单
    storageLeftMenu(state,arr){
      state.activityPartList = arr;
    },
    setUserImportFile(state,data){
      state.userImportFile = data
    },
    setIsFaBu(state,data){
      state.isFabu = data
    },
  },
  actions: {
  },
  modules: {
  },
  // 持久化vuex 插件存储
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
