import axios from '@/utils/ajax';
import Global from "@/utils/global";
//根据DeepCode获取树形区域层级信息
export const getHierarchicalByDeepCode = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL3')+'/area/getHierarchicalByDeepCode','get',params);
}
// 获取我参与的组
export const getIJoinTeacherGroup = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL3')+'/group/getIJoinTeacherGroup','get',params);
}
// 退出登录
export const logoutUser = (params)=>{
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL')+'/user/logout','get',params);
}
// 获取我的项目菜单权限
export const getMyProjectPermissionOrganizationDto = (params) => {
  return axios.ajax4UserMgt(Global.getParamsKey('BASE_URL') +'/permissionOrganization/getMyProjectPermissionOrganizationDto','get',params,true);
}