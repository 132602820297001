<template>
  <div class="list">
    <div class="list-item u-line-1" v-for="(item, index) in list">
      <div class="u-line-1">
        <div class="name u-line-1" :class="{'school': type == 'school','onearea': type == 'area' && level == 1 }" @click="toPath(item, type)">{{item[name]}}</div>
        <div class="name-list" v-if="item.children && item.children.length > 0">
          <seller-item :list="item.children" :type="type" :name="name"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sellerItem from './sellerItem'
import Cookies from 'js-cookie'
export default {
  name: 'sellerItem',
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    // 
    type: {
      type: String,
      default: 'area'
    },
    // 区分是否是第一层
    level: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: 'areaName'
    }
  },
  data() {
    return {
      organizationInfo:JSON.parse(Cookies.get('itemByQuery')),
    }
  },
  components: {
    sellerItem
  },
  methods: {
    // toPath
    toPath(item, type) {
      if (type == 'area') {
        window.open(`${this.organizationInfo.communitySiteUrl}/project/${this.$store.state.userInfo.userinfo.projectId}/index${item.areaCode ? '/' + item.areaCode : ''}`);
      } else if (type == 'school') {
        // 跳转学校或者小组
        window.open(`${this.organizationInfo.communitySiteUrl}/project/${this.$store.state.userInfo.userinfo.projectId}/groupIndex/${item.id}`)
      } 
    }
  }
}
</script>

<style scoped lang="scss">
  .list-item:nth-child(1){
    .name{
      &.school{
        margin-top: 0px;
      }
    }
  }
  .name{
    box-sizing: border-box;
    padding-left: 15px;
    position: relative;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #303133;
    margin-top: 27px;
    cursor: pointer;

    &.school{
      margin-top: 17px;
    }
    &.onearea{
      margin-top: 0;
    }
    &:hover{
      color: #16b4f4;
    }
    &::before{
      content: '';
      width: 5px;
      height: 5px;
      background: #16b4f4;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

  }
  .name-list{
    padding-left: 15px;
  }
</style>