<template>
  <div id="app">
    <div class="nav">
      <navigationBar v-if="$store.state.userInfo"></navigationBar>
    </div>
    <div class="bottom">
      <router-view />
    </div>
    <bottom_footer style="background: #16b4f4;"></bottom_footer>
    <preserve-inform></preserve-inform>
  </div>
</template>
<script>
import navigationBar from "@/views/navigationBar/index.vue";
import Cookies from "js-cookie";
import bottom_footer from '@/components/footer'
import preserveInform from '@/views/preserveInform'
export default {
  components: {
    navigationBar,
    bottom_footer,
    preserveInform
  },
  data(){
    return {
      organizationInfo:JSON.parse(Cookies.get('itemByQuery')),
      imgObj: {
        jingH:require('@/assets/jinghui.png')
      },
    }
  },
  created() {
    new CozeWebSDK.WebChatClient({
      config: {
        bot_id: '7401051998730829874',
      },
      componentProps: {
        title: 'AI智能助手【小师】',
        lang: 'zh-CN',
        icon: 'https://file.besteacher.com.cn/ai_icon.png'
      }
    });
  }
};
</script>
<style lang="scss" scoped>
#app,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #f5f7f9;
}
#app{
  min-width: 1200px;
}
.nav {
  width: 100%;
  height: 82px;
  background-color: #ffff;
}
.bottom {
  width: 100%;
  height: calc(100% - 110px);
  display: inline-table;
  margin: 0 auto;
  background-color: #f5f7f9;
}
.footer{
  width: 100%;
  height: 120px;
  background: #16b4f4;
  margin-top: 20px;
  .footer-container{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .f-text{
      display: flex;
      float: left;
      width: 100%;
      height: 50px;
      align-items: center;
      color: white;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 30px;
      img{
        vertical-align: sub;
      }
    }
    .f-qr_code{
      float: right;
      width: 600px;
      margin-top: 10px;
      text-align: center;
      img{
        width: 80px;
        height: 80px;
      }
      .fq-describe{
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        span:nth-child(1){
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
  .fda3723591e0b38e7e52{
    bottom: 70px !important;
    right: -25px !important;
    transform: rotate(-45deg);
    &:hover{
      right: 10px !important;
      transition: .5s;
      width: 70px;
      height: 80px;
      >img{
        right: 10px !important;
      }
    }

  }
</style>