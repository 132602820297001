import Cookie from 'js-cookie'

export default {
    data(){
        return {

        }
    },
    computed:{
        schoolNameT(){
            let userinfo = this.$store.state.userInfo.userinfo,
                name = '学校';
           switch (userinfo.projectType) {
               case 1:
                   name='学校';
                   break;
               case 4:
                   name='班级';
                   break;
               case 5:
                   name='班级';
                   break;
               case 3:
                   name='工作室';
                   break;
               case 7:
                   name='教研室';
                   break;
               case 9:
                   name='党支部';
                   break;
               case 10:
                   name='学校';
                   break;
               case 8:
                   name='工作室';
                   break;
               case 6:
                   name='工作室';
                   break;
           }
           return name;
        }
    },
    // beforeRouteEnter(to, form, next) {
    //     next(vm => {
    //         if (!vm.$store.state.userInfo.userinfo.forceFlag) {
    //             let organizationInfo = JSON.parse(Cookie.get('itemByQuery'))
    //             window.open(`${organizationInfo.passportSiteUrl}/finishInfo`,'_self');
    //         }
    //     })
    // },
    methods:{
        routeFun() {
            let userinfo = this.$store.state.userInfo.userinfo;
            let roleLevel = userinfo.orgUserInfoDetails[0].orgRoleLevelInt,
                href = '';
            if (roleLevel == 0) {
                href = `${this.organizationInfo.manageSiteUrl}/home/pm/page`
            }  else if (userinfo.multiSiteFlag) { //用户属于多站点
                href = `${this.organizationInfo.passportSiteUrl}/organizationList`
                // window.open(`${this.organizationInfo.archivesSiteUrl}/organizationList`, '_self')
            } else if (roleLevel == 1) { //顶级管理员顶级 1
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit/topLevel`
            } else if (roleLevel >=2 && roleLevel <= 6) { //中间
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit/middleLevel`
            } else if (roleLevel >=7 && roleLevel <= 12) { //学科
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit/subjectleLevel`
            } else if (roleLevel == 13) { //校管
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit/schoolLevel`
            } else if (roleLevel == 14 || roleLevel == 15) { //组长、教师跳转到我的项目页面
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit/selectionProject`
                this.$store.commit('leftTypeCut',2);
            } else {
                href = `${this.organizationInfo.archivesSiteUrl}/cockpit`
            }
            location.href = href;
        },
        schoolNameTans(){
            let userinfo = this.$store.state.userInfo.userinfo,
                name = '学校';
            switch (userinfo.projectType) {
                case 1:
                    name='学校';
                    break;
                case 4:
                    name='班级';
                    break;
                case 5:
                    name='班级';
                    break;
                case 3:
                    name='工作室';
                    break;
                case 7:
                    name='教研室';
                    break;
                case 9:
                    name='党支部';
                    break;
                case 10:
                    name='学校';
                    break;
                case 8:
                    name='工作室';
                    break;
                case 6:
                    name='工作室';
                    break;
            }
            return name;
        }
    }
}