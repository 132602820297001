<template>
    <div class="navigation-bar-container">
        <div class="navigation-bar">
          <div class="nb-header-img" :style="{'background':`url(${organizationInfo.logo})`}"></div>
          <div class="navigation-bar-right u-flex">
            <div class="navigation-bar-list u-flex">
              <div class="navigation-bar-item right-border" @click="toPath('main')" v-if="organizationInfo && organizationInfo.dataEntityState == 0 && ($store.state.userInfo.userinfo.projectId!==528 || $store.state.userInfo.userinfo.systemManager)">
                <!-- <a :href="organizationInfo.communitySiteUrl"> -->
                  <div class="navigation-bar-item-content">
                    {{organizationInfo.homepageTypeInt == 1 ? '区域导航' : '区域门户'}}
                  </div>
                <!-- </a> -->
              </div>
              <div class="navigation-bar-item right-border" v-if="$store.state.userInfo && $store.state.userInfo.userinfo.systemManager && $store.state.userInfo.userinfo.projectId">
                <a :href="organizationInfo.communitySiteUrl + '/project/' + $store.state.userInfo.userinfo.projectId + '/index'">
                  <div class="navigation-bar-item-content">
                    区域社区
                  </div>
                </a>
              </div>
              <div class="navigation-bar-item right-border" v-else-if="$store.state.userInfo && (($store.state.userInfo.userinfo.projectId == 77 && $store.state.userInfo.userinfo.roleLevelInt <= 6) || $store.state.userInfo.userinfo.projectId != 77) && $store.state.userInfo.userinfo.projectId!==528">
                <el-popover
                  placement="bottom"
                  width="126"
                  trigger="hover" 
                  popper-class="schoolPop"
                  >
                  <div class="list-box">
                    <seller-item :list="areaData" type="area" :level="1" name="areaName"/>
                  </div>
                  <div class="navigation-bar-item-content-1 u-flex" slot="reference">
                    区域社区
                    <i class="el-icon-arrow-down right-arrow"></i>
                  </div>
                </el-popover>
              </div>
              <div class="navigation-bar-item right-border" v-if="$store.state.userInfo && $store.state.userInfo.userinfo.roleLevelInt == 13 || $store.state.userInfo.userinfo.roleLevelInt == 14 || $store.state.userInfo.userinfo.roleLevelInt == 15" @click="toPath('school', { projectId: $store.state.userInfo.userinfo.projectId, schoolId: $store.state.userInfo.userinfo.schoolId})">
                <el-popover
                  placement="bottom"
                  width="126"
                  trigger="hover"
                  popper-class="schoolPop"
                >
                <!-- trigger="hover" -->
                  <div class="list-box">
                    <div class="schoolItem" v-if="groupArr && groupArr.length > 0">
                      <seller-item :list="groupArr" type="school" name="teacherGroupName" :level="1"/>
                    </div>
                    <div v-else class="nogroup">暂无小组</div> 
                  </div>
                  <div class="navigation-bar-item-content-1 u-flex" slot="reference">
                    {{schoolNameT}}社区
                    <i class="el-icon-arrow-down right-arrow"></i>
                  </div>
                </el-popover>
              </div>
              <!-- <div class="navigation-bar-item" @click="toPath('mySpace')">
                <div class="navigation-bar-item-content active">
                  我的空间
                </div>
              </div> -->
              <div class="navigation-bar-item" v-if="$store.state.userInfo && $store.state.userInfo.userinfo.roleLevelInt >= 7">
                <a :href="toPath('mySpace')">
                  <div class="navigation-bar-item-content active">
                    我的工作室
                  </div>
                </a>
              </div>
              <div class="navigation-bar-item" v-else-if="$store.state.userInfo">
                <a :href="toPath('admin')">
                  <div class="navigation-bar-item-content active">
                    管理后台
                  </div>
                </a>
              </div>
              <div class="choosePorjectBtn u-flex"  @click="chooseSelectionProject" v-if="$store.state.userInfo && isShowMyProjectMenu && $store.state.userInfo.userinfo.needShowSwitchRole && ($store.state.userInfo.userinfo.projectId!==528 || $store.state.userInfo.userinfo.systemManager)">
                <div class="choose-icon">
                  <img src="@/assets/cub.png" class="image"/>
                </div>
                <div class="choosePorjectBtn-text">
                  我的项目
                </div>
              </div>
              <div class="choosePorjectBtn u-flex myPerson" @click="routeFun" v-if="!$store.state.userInfo.userinfo.systemManager && orgTeacher($store.state.userInfo.userinfo)">
                <div class="choose-icon">
                  <img src="@/assets/myPer.png" class="image"/>
                </div>
                <div class="choosePorjectBtn-text">
                  {{orgRoleLevel($store.state.userInfo.userinfo) <= 3 ? '我的工作台' : '个人档案'}}
                </div>
              </div>
            </div>
            <el-dropdown @command="handleCommand" v-if="$store.state.userInfo">
              <span class="el-dropdown-link">
                <div class="user-info u-flex">
                  <div class="user-header">
                    <img :src="$store.state.userInfo.userinfo.userLogo || headdefault" class="image"/>
                  </div>
                  <div class="user-name">
                    {{$store.state.userInfo.userinfo.name}}
                  </div>
                  <div style="margin-top: 2px;">
                    <i class="el-icon-arrow-down right-arrow"></i>
                  </div>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown" class="infoPop">
                  <el-popover
                    placement="left"
                    width="130"
                    trigger="hover"
                    effect="light"
                    popper-class="allInfo"
                    v-if="$store.state.userInfo"
                    >
                    <div class="name-role">
                      {{$store.state.userInfo.userinfo.name}}
                      <span class="line-role">|</span>
                      <template v-if="$store.state.userInfo.userinfo.roleName || $store.state.userInfo.userinfo.orgUserInfoDetails[0].orgRoleName">
                        {{$store.state.userInfo.userinfo.roleName || $store.state.userInfo.userinfo.orgUserInfoDetails[0].orgRoleName}}
                      </template>
                    </div>
                    <div class="name-role u-line-2" slot="reference">
                      {{$store.state.userInfo.userinfo.name}}
                      <span class="line-role">|</span>
                      <template v-if="$store.state.userInfo.userinfo.roleName || $store.state.userInfo.userinfo.orgUserInfoDetails[0].orgRoleName">
                        {{$store.state.userInfo.userinfo.roleName || $store.state.userInfo.userinfo.orgUserInfoDetails[0].orgRoleName}}
                      </template>
                    </div>
                  </el-popover>
                  <el-dropdown-item command="main" v-if="$store.state.userInfo && !$store.state.userInfo.userinfo.systemManager">我的主页</el-dropdown-item>
                  <el-dropdown-item command="b">账号管理</el-dropdown-item>
                  <el-dropdown-item command="return">退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="navigation-text" v-if="pattern=='default' && false">
            <div class="nt-container">
                <span>{{$store.state.userInfo.userinfo.projectName}}</span>
                <!-- 如果有多个项目，显示项目跳转 -->
                <!-- <el-button round class="cut-btn" @click="chooseSelectionProject" v-if="$store.state.userInfo.userinfo.needShowSwitchRole">切换角色/项目
                  <i class="s-icon-green-arrows"></i>
                </el-button> -->
            </div>
        </div>
        <div class="navigation-text-special" v-if="pattern=='special'"> </div>
    </div>
</template>

<script>
    import Cookies from 'js-cookie'
    import utils from '@/utils/util';
    import sellerItem from './sellerItem'
    import headdefault from '@/assets/headdefault.png'
    import { getHierarchicalByDeepCode, getIJoinTeacherGroup, logoutUser, getMyProjectPermissionOrganizationDto } from './service/index'
    export default {
        name: "index",
        props:{
          pattern:{
              type:String,
              default:()=>{
                  return 'default'
              }
          }
        },
        components: {
          sellerItem
        },
        data() {
            return {
              headdefault,
                navArr: [
                  {
                    name: '科技组'
                  },
                  {
                    name: '语文组'
                  },
                  {
                    name: '数学组'
                  },
                  {
                    name: '英语组'
                  },
                  {
                    name: '物理组'
                  },
                  {
                    name: '化学组'
                  },
                  {
                    name: '生物组'
                  }
                ],
                // 区域数据
                areaData: [],
                // 群组数据
                groupArr: [],
                organizationInfo:JSON.parse(Cookies.get('itemByQuery')),
                flag: true,
                isShowMyProjectMenu: false
            }
        },
        created() {
            // (this.$store.state.userInfo.userinfo.projectType==3)?this.navArr[3].name='工作室社区':'';
          if (!this.$store.state.userInfo.userinfo.systemManager) {
            this.getMyProjectPermission()
          }
        },
        mounted() {
          console.log(JSON.parse(Cookies.get('itemByQuery')))
          console.log('userinfo',this.$store.state.userInfo.userinfo)
          if (!this.$store.state.userInfo.userinfo.systemManager) {
            if (this.$store.state.userInfo.userinfo.projectId == 77) {
              if (this.$store.state.userInfo.userinfo.roleLevelInt <= 6) {
                this.getHierarchicalByDeepCode(this.$store.state.userInfo.userinfo.deepCode)
              }
            } else {
              this.getHierarchicalByDeepCode(this.$store.state.userInfo.userinfo.deepCode)
            }
          }
          if (this.$store.state.userInfo.userinfo.roleLevelInt == 13 || this.$store.state.userInfo.userinfo.roleLevelInt == 14 || this.$store.state.userInfo.userinfo.roleLevelInt == 15) {
            this.getIJoinTeacherGroup()
          }
        },
        methods: {
          /**
           * 1 区域管理员
           * 2 教研员
           * 3 校长
           * 4 组长
           * 5 教师
           */
          orgRoleLevel(userInfo) {
            let text = 5
            if (!userInfo) return text
            if (userInfo.orgUserInfoDetails && userInfo.orgUserInfoDetails.length > 0) {
              if (userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt >= 1 && item.orgRoleLevelInt <= 6).length > 0) {
                text = 1
              } else if (userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt >= 7 && item.orgRoleLevelInt <= 12).length > 0) {
                text = 2
              } else if (userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt == 13).length > 0) {
                text = 3
              } else if (userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt == 14).length > 0) {
                text = 4
              } else if (userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt == 15).length > 0) {
                text = 5
              }
            } else {
              text = 5
            }
            return text
          },
          //判断是否只拥有教师角色
          orgTeacher(userInfo){
            let isTrue = true;
            if(!userInfo || userInfo.orgUserInfoDetails.length>1){
              isTrue = true;
            }else if(userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt == 15).length > 0 || userInfo.orgUserInfoDetails.filter(item => item.orgRoleLevelInt == 14).length > 0){
              isTrue = false;
            }
            return isTrue;
          },
          chooseMyPerson() {
            if (this.orgRoleLevel(this.$store.state.userInfo.userinfo) <= 2 ) {
              window.open(this.organizationInfo.archivesSiteUrl + '/credit/manage')
            } else {
              window.open(this.organizationInfo.archivesSiteUrl + '/credit/statistics')
            }
          },
          /**
           * 切换选择项目页
           */
          chooseSelectionProject() {
            // setTimeout(() => {
            //   this.$store.commit('getUserInfo', {
            //     userinfo: {},
            //     token: null
            //   })
            // }, 100)
            window.open(this.organizationInfo.archivesSiteUrl + '/cockpit/selectionProject', "_self")
          },
          /**
           * 获取我参与的小组
           */
          getIJoinTeacherGroup() {
            getIJoinTeacherGroup().then(res => {
              console.log('group', res.data)
              this.groupArr = res.data
            })
          },
          /**
           * 获取区域列表 
           */
          getHierarchicalByDeepCode(deepCode) {
            const params = {
              getAll: true,
              deepCode: deepCode
            }
            getHierarchicalByDeepCode(params).then(res => {
              if (res && res.data) {
                this.areaData = res.data
                console.log(this.areaData)
              }
            })
          },
          handleCommand(command) {
            if (command == 'return') {
              this.exitProject()
            } else if (command == 'main') {
              this.toPath('user', {projectId: this.$store.state.userInfo.userinfo.projectId, userId: this.$store.state.userInfo.userinfo.userId, deepCode: this.$store.state.userInfo.userinfo.deepCode})
            }else{
              // 用户管理页面
              window.open(this.organizationInfo.homeSiteUrl, '_self');
            }
          },
          toPath(type, item) {
            // 社区首页
            if (type == 'main') {
              if (this.organizationInfo.homepageTypeInt == 1) {
                  window.open(this.organizationInfo.communitySiteUrl, "_self")
              } else{
                  window.open(this.organizationInfo.communitySiteUrl + '/sites/' + this.$store.state.userInfo.userinfo.organizationId, "_self")
              }
              // window.open(this.organizationInfo.communitySiteUrl, "_self")
              // 项目首页
            } else if(type == 'project') {
              window.open(this.organizationInfo.communitySiteUrl + '/project/' + item.projectId + '/index', '_self');
              // window.open(this.organizationInfo.communitySiteUrl, "_self")
            } else if (type == 'user') {
              // 用户首页
              window.open(`${this.organizationInfo.communitySiteUrl}/project/${item.projectId}/teacherIndex/${item.userId}${ item.deepCode ? '/' + item.deepCode : '' }`);
            } else if (type == 'mySpace') {
              let studioSiteUrl = this.organizationInfo?.studioSiteUrl,
                      userinfo = this.$store.state.userInfo.userinfo;
              //判断是否是名师工作室
              if(userinfo.projectType == 3){
                studioSiteUrl += '/trainingGuidance/famousTeacherPage';
              }else{
                if(userinfo.roleLevelInt  == 15){
                  //教师首页
                  studioSiteUrl += '/trainingGuidance/teacherHomePage';
                }else if(userinfo.roleLevelInt  == 13){
                  //校长首页
                  studioSiteUrl += '/trainingGuidance/schoolStatistics';
                }else if(userinfo.roleLevelInt  == 14){
                  //组长首页
                  studioSiteUrl += '/trainingGuidance/groupHomePage';
                }else if(userinfo.roleLevelInt > 6 && userinfo.roleLevelInt < 13){
                  //教研员首页
                  studioSiteUrl += '/trainingGuidance/researchHomePage';
                }
              }
              // this.$store.commit('setMenuActiveItem',null);
              // 我的空间
              // window.open(`${studioSiteUrl}`, '_self');
              return studioSiteUrl
            } else if (type == 'school') {
              // 学校首页
              window.open(`${this.organizationInfo.communitySiteUrl}/project/${item.projectId}/schooldIndex/${item.schoolId}`);
            } else if (type == 'admin') {
              let userinfo = this.$store.state.userInfo.userinfo;
              let url = ''
              //1.判断是否为系统用户
              if(userinfo.systemManager){
                // 后台首页
                // window.open(`${this.organizationInfo.manageSiteUrl}/home/pm/page`);
                url = `${this.organizationInfo.manageSiteUrl}/home/pm/page`
              }else if(userinfo.roleLevelInt<=6){  //当有项目ID时，判断下角色等级
                //当小于等于6时，直接进入管理员后台项目首页
                url = `${this.organizationInfo.manageSiteUrl}/project/default/page`
                // window.open(`${this.organizationInfo.manageSiteUrl}/project/default/page`);
              }
              return url
            }
          },
          exitProject(){
              this.$confirm('确定退出登录吗？')
                  .then(_ => {
                    logoutUser().then(res => {
                      if (res && res.code == 200) {
                        utils.toLogout();
                      } else {
                        this.$message.error('退出登录失败')
                      }
                    })
                  })
                  .catch(_ => {});
          },
          handleSelect(item) {
              if(item == '工作室社区'){
                window.open('file:///D:/dev/%E5%90%8D%E5%B8%88%E5%B7%A5%E4%BD%9C%E5%AE%A4/%E5%85%AC%E5%91%8A.html')
              }
          },
          getMyProjectPermission() {
            let obj = {
                organizationId: this.$store.state.userInfo.userinfo.organizationId,
                roleId: this.$store.state.userInfo.userinfo.orgUserInfoDetails?this.$store.state.userInfo.userinfo.orgUserInfoDetails[0].roleId:this.$store.state.userInfo.userinfo.roleId
            }
            getMyProjectPermissionOrganizationDto(obj).then(res => {
                console.log(res)
                let myProjectMenuList = res.data?res.data.organizationPermissionDtos : []
                this.isShowMyProjectMenu = myProjectMenuList.length>0?true:false
            })
          },
        }
    }
</script>

<style scoped lang="scss">
  .list-box{
    box-sizing: border-box;
    padding: 10px 10px;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    // .list-item:nth-child(1){
    //   margin-bottom: 2px;
    // }
    // & > .list-item:nth-child(1){
    //   .name{
    //     margin-top: 0
    //   }
    // }
    .schoolName{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      padding-bottom: 6px;
      cursor: pointer;
      &:hover{
        color: #16b4f4;
      }
    }
    .schoolItem{
      padding-left: 9px;
    }
  }
  .list-box>.list>.list-item:nth-child(1)>.u-line-1 .name{
    margin-top: 0px;
  }
  .user-info{
    // margin-left: 35px;
    &:hover{
      color: #16b4f4;
      .user-job{
        color: #16b4f4;
      }
      .user-name{
        color: #16b4f4;
      }
    }
    cursor: pointer;
    .user-header{
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 50%;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .user-name{
      padding: 0 8px 0 5px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
      // &.right-border{
      //   &::after{
      //     content: '';
      //     position: absolute;
      //     right: 0;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     width: 1px;
      //     height: 16px;
      //     background: #D1DBF3;
      //   }
      // }
    }
    .user-job{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      position: relative;
      padding: 0 8px 0 8px;
      max-width: 80px;
      &.left-border{
        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 16px;
          background: #D1DBF3;
        }
      }
    }
  }
  .nogroup{
    text-align: center;
  }
  .el-popover.schoolPop{
    min-width: 126px;
  }
  .choosePorjectBtn{
    // width: 100px;
    padding: 0 10px;
    height: 31px;
    justify-content: center;
    background: rgba(22, 180, 244,0.09);
    border-radius: 22px 22px 22px 22px;
    border: 1px solid #16b4f4;
    margin-right: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    &.myPerson{
      background: rgba(22, 180, 244,0.07);
      border-radius: 22px 22px 22px 22px;
      opacity: 1;
      border: 1px solid #16b4f4;
      .choosePorjectBtn-text{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #16b4f4;
      }
      .choose-icon{
        width: 20px;
        height: 15px;
        margin-right: 7px;
      }
    }
    .choose-icon{
      width: 16px;
      height: 16px;
      display: flex;
      margin-right: 8px;
      .image{
        width: 16px;
      }
    }
    // &:hover{
    //   .choosePorjectBtn-text{
    //     color: #16b4f4;
    //   }
    // }
    .choosePorjectBtn-text{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #16b4f4;
    }
  }
</style>