import axios from 'axios';
import Cookie from 'js-cookie'
import util from '@/utils/util';
const httpRequest = (resolve,reject,config,catchReject)=>{
    axios(config).then(response =>{
        //请求接口返回正确时
        if(response.data.code === 200){
            resolve(response.data || {});
            //后续跟后端定义好返回状态码，来处理token过期和seesion过期情况
        }else if(response.data.code === 403 || response.data.code === 401){  //针对token过期，直接跳转到登录页
            util.errorMessage('长时间未操作，请重新登录!');
            util.exit();
        }else{
            if (response.status === 200) {
                resolve(response.data || {});
            }else if(catchReject){
                reject(response.data)
            }else{
                util.errorMessage(response.data.message || '系统异常，请求失败~~~');
            }
        }
    }).catch(err=>{
        let errT = err.toString();
        if(errT.indexOf('403') != -1 || errT.indexOf('401') != -1){  //针对token过期，直接跳转到登录页
            util.errorMessage('登录过期，请重新登录!');
            util.exit();
        }else{
            util.errorMessage(err.message || '系统异常，请求失败~~~');

            if(catchReject){
                reject(err)
            }
        }
    })
}

//获取token
const getSessionStorageVuex = (key)=>{
    let vuex = sessionStorage.getItem('vuex');
    vuex = vuex && JSON.parse(vuex);
    return (vuex && vuex[key]) || null;
}

axios.ajax4UserMgt = function ajax(url,method,params,catchReject, extend = {},accessory){
    return new Promise((resolve,reject)=>{
        let config = null,
            token = Cookie.get('access_token') || getSessionStorageVuex('token'); //存储token值
        config = {
            url,
            method
        };
        if(accessory){
            config.responseType = accessory;
        }
        config.headers = {
            'Content-type': 'application/json;charset=utf-8'
        };
        if(token){
            config.headers['access-token'] = token;
        }
        if(method && (method.toLowerCase() === 'get' || method.toLowerCase() === 'put')){
            config.params = params;
        }else if(method.toLowerCase() === 'put1'){
            config.method = 'put';
            config.data = params;
        }else{
            config.data = params;
        }
        httpRequest(resolve,reject,config,catchReject,extend);
    })
};
export default axios;