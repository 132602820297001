import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Global from "@/utils/global";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/rem'
import '@/static/theme/index.scss';
import "../public/img/icon-font/iconfont.css"
import wangEditor from "@/components/wangEditor";
import ossUpload from "@/components/ossUpload";//oss上传附件组件
import mixins from '@/utils/mixing'
import emoji from '@/utils/emoji';
import '../public/iconfont/iconfont.css'

// import Vue2Editor from "vue2-editor";

// Vue.use(Vue2Editor);
import Cookies from "js-cookie"
//引入饼状图
import * as echarts from 'echarts'

ElementUI.Select.props.filterable = {
  type: Boolean,
  default: true
}
ElementUI.Cascader.props.filterable = {
  type: Boolean,
  default: true
}
ElementUI.Button.props.size={
  type: String,
  default: 'mini'
};
ElementUI.Select.props.size={
  type: String,
  default: 'mini'
};
ElementUI.Input.props.size={
  type: String,
  default: 'mini'
};
ElementUI.Cascader.props.size={
  type: String,
  default: 'mini'
};
ElementUI.DatePicker.props.size={
  type: String,
  default: 'mini'
};
import moment from 'moment';
Vue.prototype.$moment = moment;
Vue.component('editors',wangEditor)
Vue.component('ossUpload', ossUpload)
Vue.directive('emoji', emoji)
//注册全局
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.mixin(mixins)
Global.init().then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
      let itemByQuery = Cookies.get('itemByQuery')
      itemByQuery && Global.setFavicon(JSON.parse(itemByQuery));
    }
  }).$mount('#app')
});