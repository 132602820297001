<template>
    <div class="oss-upload">
        <el-upload
                ref="upload"
                action
                :show-file-list="false"
                multiple
                :on-change="handleChange"
                :auto-upload="false"
                :accept="accept"
        >
            <el-button icon="el-icon-paperclip" type="primary">上传附件</el-button>
            <div slot="tip" class="el-upload__tip">支持格式：doc(docx)、ppt(pptx)、xls(xlsx)、pps(ppsx)、txt、pdf、zip、rar、mp4、png、jpg 单个附件不超过 1G</div>
            <slot></slot>
        </el-upload>
        <div class="file-list">
            <div class="file-item" v-for="(item, index) in fileList" :key="(item.url || (item.uid+index))">
                <!-- <svg class="icon-file" aria-hidden="true">
                  <use :xlink:href="`#icon-${fileTypeIcon(item)}`"></use>
                </svg> -->
                <div class="file-name">
                    <div class="name">
                        <span class="file-name-item">
                            {{ index + 1 }}.{{ item.name }}
                        </span>
                        <span class="speed" v-if="item.isPlay && item.percentage !== 100">
                            {{ item.speed }}/s
                        </span>
                        <span v-if="item.percentage === 100" class="success">完成</span>
                        <!-- <div class="total" v-if="item.tempCheckpoint">
                          {{ filterSize(item.partSize) }}/{{ filterSize(item.size) }}
                        </div> -->
                        <div class="total">
                            {{ filterSize(item.size) }}
                        </div>
                    </div>
                    <span class="name error" v-if="item.errMsg">{{ item.errMsg }}</span>
                    <el-progress
                            :percentage="item.percentage"
                            v-if="item.percentage < 100 && !item.errMsg"
                    ></el-progress>
                    <template v-else>
                        <el-progress
                                :percentage="item.percentage"
                                :status="item.errMsg ? 'exception' : 'success'"
                        ></el-progress>
                    </template>
                </div>
                <div class="tool">
                    <span v-if="!item.percentage || (0 < item.percentage < 100 && !item.isPlay)"
                          class="icon delete"
                          @click="handleDeleteChangeFile(index,item)"
                    >
                      <i class="el-icon-close"></i>
                    </span>
                    <span v-if="item.percentage && (item.percentage == 100)"
                          class="icon delete"
                          @click="handleDeleteChangeFile(index,item)"
                    >
                      <i class="el-icon-close"></i>
                    </span>
                    <span
                            v-if="item.percentage && item.percentage !== 100"
                            class="icon"
                            :class="item.isPlay ? 'delete' : 'success'"
                            @click="handleStopChangeFile(index, item)"
                    >
                        <i :class="`el-icon-${item.isPlay ? 'video-pause' : 'caret-right'}`"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ossService from "@/utils/oss";
    import global from "@/utils/global";
    export default {
        name: "ossUpload",
        props:{
            //限制文件上传类型
            accept:{
                type: String,
                default: '.docx,.doc,.ppt,.pptx,.xls,.xlsx,.pps,.ppsx,.txt,.pdf,.zip,.rar,.mp4,.png,.jpg,.jpeg'
            },
            //最大允许上传个数
            limit:{
                type: Number,
                default: 9999
            },
            //oss上传目录
            uploadFileType:{
                type: Number,
                default: 40
            },
            fileListEdit:{
                type:Array,
                default:[]
            }
        },
        watch:{
            fileListEdit: {
                immediate:true,
                handler(newval, old) {
                    if (newval && newval.length) {
                        let arr = JSON.parse(JSON.stringify(newval)),
                            isRepetition = false;
                        arr.forEach((item1)=>{
                            isRepetition = false;
                            item1.abortCheckpoint = false;
                            item1.isLoading = false;
                            item1.isPlay = true;
                            item1.percentage = 100;
                            if(this.fileList.length){
                                this.fileList.forEach((item2)=>{
                                    if(item2.url == item1.url){
                                        isRepetition = true;
                                    }
                                })
                                !isRepetition && this.fileList.push(item1);
                            }else{
                                this.fileList.push(item1);
                            }

                        })
                    }else{
                        this.fileList = [];
                    }
                },
                deep: true,
            },
        },
        data(){
            return {
                fileList:[],
                oss:null
            }
        },
        mounted() {
            this.oss = new ossService();
        },
        destroyed() {
            this.handleStopFile();
        },
        methods:{
            //暂停所有正在上传的文件
            handleStopFile(){
                this.fileList.forEach(item=>{
                    if (item.isPlay&&item.client) item.client.cancel()
                })
            },
            handleStopChangeFile(index, item) {
                item.isPlay = !item.isPlay
                this.fileList.splice(index, 1, item)
                // window.removeEventListener('online', this.resumeUpload)
                if (!item.isPlay) item.client.cancel()
                else this.resumeMultipartUpload(item)
            },
            handleDeleteChangeFile(index,args) {
                //(item.id && args.id == item.id) || (item.uid && item.uid==args.uid)
                let index1 = this.fileList.findIndex((item,index) => {
                    let status;
                    if(args.url){
                        status = item.url == args.url;
                    }else{
                        status = item.uid == args.uid;
                    }
                    return status;
                });
                this.fileList.splice(index1, 1);
                this.$emit('fileChange',this.fileList);
            },
            /**
             * @description 恢复上传
             */
            async resumeMultipartUpload(item) {
                // 恢复单文件
                if (item) {
                    const { tempCheckpoint } = item
                    this.resumeUploadFile(item, tempCheckpoint)
                }
            },
            async resumeUploadFile(item, checkpoint) {
                // const { uploadId, file, name } = checkpoint
                try {
                    this.submitForm(item,checkpoint);
                } catch {
                    console.log('---err---')
                }
            },
            handleExceed() {
                this.$confirm(`上传文件数量不能超过${this.limit}个，请重新选择！`, '操作提示', {
                    confirmButtonText: '确 定',
                    cancelButtonText: '取 消',
                    type: 'warning',
                })
            },
            /**
             * @description 判断文件类型
             * @param {*} file
             */
            nameFilter(file){
                // 截取上传文件的后缀名
                let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
                if(this.accept.indexOf(fileType.toLowerCase()) === -1){
                    return false;
                }else{
                    return true;
                }
            },
            /**
             * @description 选择文件
             * @param {*} file
             * @param {*} fileList
             */
            handleChange(file, fileList) {
                //判断文件大小
                //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
                const is1G = file.size / 1024 / 1024 / 1024 < 1,
                      isTrue = this.nameFilter(file);
                if(!is1G){
                    this.$message.warning("上传附件大小不能超过 1G!");
                    return false
                }
                if(!isTrue){
                    this.$message.warning("请上传正确的文件格式!");
                    return false
                }
                if(this.fileList.length === this.limit){
                    this.handleExceed();
                    return false;
                }
                let fileCopy = file;
                fileCopy.client = null
                fileCopy.isPlay = false
                fileCopy.isLoading = false
                fileCopy.abortCheckpoint = false
                fileCopy.percentage = 0;
                this.fileList.push(fileCopy);
                this.submitForm(fileCopy);
                //上传前将文件传给父组件，防止保存时 还有文件没有上传完
                this.$emit('fileChange',this.fileList);
            },
            submitForm(file,checkpoint) {
                let name = this.oss.getFileNameUUID() + file.name;
                file.partSize = 0
                this.oss.multipartUpload({uploadFileType:this.uploadFileType},name, file,this.onUploadProgress,checkpoint).then(res=>{
                    res = res.res;
                    if(!res.requestUrls){
                        this.$message({
                            message: `上传文件失败!`,
                            type: 'error'
                        });
                        return;
                    }
                    let url = this.oss.urlTransform(res.requestUrls[0],global.getParamsKey("IMG_URL"));
                    url = url.replace(/(\?|#)[^'"]*/, '');
                    const attachment = {
                        name:file.name,
                        size:file.size,
                        extension:file.name.split(".").pop(),
                        url:url,
                        ossUrl:res.requestUrls[0],
                        ossName:res.name,
                        uid:file.uid
                    };
                    Object.assign(file,attachment);
                    this.$emit('fileStatus',true);
                    this.$emit('fileChange',this.fileList);
                }).catch(async (err)=>{
                    if(checkpoint){
                        await this.resetUpload(err, file)
                    }
                })
            },
            async resetUpload(err, item) {
                const msg = JSON.stringify(err)
                if (msg.indexOf('Error') !== -1) {
                    if (item.client) {
                        item.client.cancel()
                    }
                    let res = await this.oss.getAssumeRole({uploadFileType:this.uploadFileType});
                    item.client = new OSS(res.data);
                    await this.resumeMultipartUpload(item)
                }
            },
            //计算百分比
            async onUploadProgress(item, p, checkpoint, res, path) {
                if (checkpoint) {
                    item.speed = this.handle_network_speed(res, 1024 * 1024, p)
                    item.tempCheckpoint = checkpoint
                    item.abortCheckpoint = checkpoint
                    item.upload = checkpoint.uploadId
                }
                // 改变上传状态
                item.isPlay = true
                // 改变准备就绪状态
                if (item.isPlay) item.isLoading = false

                item.uploadName = path
                // 上传进度
                item.percentage = Number(p)>1?100:Number((p * 100).toFixed(2));
            },
            /**
             * @description 获取上传的网络状态
             * @param {*} res 文件信息
             * @param {*} partSize 分片大小
             * @param {*} p 上传进度
             * @returns 网速度 network_speed
             */
            handle_network_speed(res, partSize, p) {
                const spend_time = res.rt / 1000 //单位s
                const end_time = new Date(res.headers.date).getTime()
                const start_time = end_time - spend_time
                let network_speed = parseInt(partSize / spend_time) // 每s中上传的字节(b)数
                if (p === 0) network_speed = 0
                if (network_speed === 0) {
                    // nothing to do
                } else {
                    this.handle_network_speed_change(start_time, end_time, network_speed)
                }
                return network_speed ? this.filterSize(network_speed) : 0
            },
            filterSize(size){
                if (!size) return '-'
                if (size < this.pow1024(1)) return size + ' B'
                if (size < this.pow1024(2)) return (size / this.pow1024(1)).toFixed(2) + ' KB'
                if (size < this.pow1024(3)) return (size / this.pow1024(2)).toFixed(2) + ' MB'
                if (size < this.pow1024(4)) return (size / this.pow1024(3)).toFixed(2) + ' GB'
                return (size / this.pow1024(4)).toFixed(2) + ' TB'
            },
            pow1024(num) {
                return Math.pow(1024, num)
            },
            async handle_network_speed_change(start_time, end_time, network_speed) {
                // 如果超过10秒没有传输数据,则清空map
                if (start_time - this.map_max_key >= 10000) {
                    this.fileMap = {}
                }
                for (let i = start_time; i <= end_time; i++) {
                    const value = await this.fileMap[i]
                    if (value) {
                        await this.change(i, value + network_speed)
                    } else {
                        await this.change(i, network_speed)
                    }
                }
            },
            async change(i, value) {
                this.fileMap[i] = value
                this.map_max_key = i
            },
            /**
             * @description 删除文件信息
             * @param {*} url
             */
            handleDeleteFile(url) {
                const startIndex = url.indexOf(this.model)
                const key = url.substring(startIndex, url.length)
                console.log(key, '根据key删除文件')
                this.$emit('on-delete')
                this.fileList = []
                this.$message.success('删除成功')

            },
        }
    }
</script>

<style  lang="scss" scoped>
    .oss-upload{
        display: inline-block;
        width: calc(100% - 20px);
        margin-left: 10px;
    }
    ::v-deep .el-upload{
        vertical-align: middle !important;
    }
    .file-list {
        max-height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .icon-file {
        width: 2.5em;
        height: 2.5em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
    ::v-deep {
        .el-progress-circle {
            width: 40px !important;
            height: 40px !important;
        }
    }
    .file-item {
        display: flex;
        align-content: center;
        .file-name {
            flex: 1;
            .name {
                width: 100%;
                display: flex;
                .total {
                    margin-left: 20px;
                    color: #606266;
                    font-size: 12px;
                }
                // justify-content: space-between;
                .file-name-item {
                    font-weight: 500;
                    width: 290px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    text-align: left;
                    color: #606266;
                    font-size: 12px;
                }
                .speed {
                    width: 120px;
                    text-align: center;
                    font-size: 13px;
                    color: #485cdf;
                }
                .success {
                    text-align: center;
                    width: 120px;
                    color: #91cc75;
                }
                &.error {
                    color: #f45;
                    font-size: 12px;
                }
            }
        }
        border-bottom: 1px solid #ddd;
        padding: 15px 0;
        &:last-child {
            border-bottom: 0;
        }
        .tool {
            margin-left: 15px;
            .icon {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                background-color: #eee;
                border-radius: 5px;
                margin: 0px 4px;
                cursor: pointer;
                font-size: 15px;
                color: rgb(255, 68, 85);
                font-weight: 600;
                &.success {
                    color: #91cc75;
                    background-color: #eee;
                }
            }
        }
    }
    .head-title {
        text-align: left;
    }
    .dialog-head {
        display: flex;
        justify-content: space-between;
    }
    ::v-deep {
        .el-progress-bar {
            width: 320px !important;
        }
        .el-progress {
            text-align: left;
        }
    }
    .num {
        background: #515256a8;
        padding: 2px 8px;
        border-radius: 4px;
        margin-left: 5px;
        color: #fff;
    }
</style>