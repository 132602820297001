<template>
  <div class="portal-footer-in" :class="{'light': colorType == 1}">
    <div class="navigation-bar-footer">
      <div class="footer-link u-flex" v-if="links && links.length > 0">
        <div class="footer-link-title u-flex u-row-center">
          <img src="@/static/portalImages/link.png" alt="" class="image">
          友情链接：
        </div>
        <div class="footer-link-item">
          <a v-for="link in links" :href="link.link" target="_blank">{{link.name}}</a>
        </div>
      </div>
      <div class="footer-center u-flex u-row-between">
        <div class="footer-left-info">
          <div class="footer-top u-flex">
            <div class="companyInfo">单位信息：{{itemQuery.unitInformation || '北京强师科技有限公司'}}</div>
            <div class="law">
              <a style="color: white;" :href="'https://www.beian.gov.cn/portal/registerSystemInfo?recordcode='+ itemQuery.beianCode" target="_blank">{{itemQuery.beianContent}}</a>
            </div>
          </div>
          <div class="footer-bottom u-flex">
            <div class="footer-banben">
              {{itemQuery.copyrightNotice}}
              <a style="color: white;" href="https://beian.miit.gov.cn/" target="_blank">{{itemQuery.icpCode}}</a>
            </div>
          </div>
        </div>
        <div class="footer-right-info u-flex">
          <div class="footer-img" v-if="itemQuery.unitLogo">
            <img class="footerimg" :src="itemQuery.unitLogo" alt="">
          </div>
          <div class="footer-img" v-if="itemQuery.unitLogoTwo">
            <img class="footerimg" :src="itemQuery.unitLogoTwo" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import * as Api_portal from '@/api/portal'
  import Cookies from "js-cookie";

  export default {
    name: "portal-footer",
    data() {
      return {
        links: [],
        shuju:JSON.parse(Cookies.get("itemByQuery")),
        imgObj: {
          jingH:require('@/assets/jinghui.png')
        },
      }
    },
    props: {
      id: {
        type: [Number, String],
        default: 0
      },
      // 页面类型 0首页 1为详情页
      pageType: {
        type: Number,
        default: 0
      },
      // 颜色类型 默认是0  1为浅色
      colorType: {
        type: Number,
        default: 0
      }
    },
    watch: {
      id(newval, oldval) {
        console.log(newval)
        this.getFriendlinkByOrganizationId()
      }
    },
    computed: {
      itemQuery() {
        return  JSON.parse(Cookies.get("itemByQuery"))
      },
      // 监听store的用户信息
      userInfo() {
        return this.$store.state.userInfo || null
      }
    },
    mounted() {
      console.log(JSON.parse(Cookies.get("itemByQuery")),12345)
      // console.log(this.$store.state.userInfo.userinfo.organizationId)
      // organizationId: this.$store.state.userInfo.userinfo.organizationId
      this.getFriendlinkByOrganizationId()
    },
    methods: {
      getFriendlinkByOrganizationId() {
        // if (!this.id && !this.$route.params.id && !this.$store.state.userInfo.userinfo.organizationId) return
        Api_portal.getFriendlinkByOrganizationId({
          organizationId: this.shuju.id
        }).then(response=>{
          if(response && response.data && response.code ==200){
            this.links = response.data || [];
          } else {
            this.links = []
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .navigation-bar-footer{
    width: 1200px;
    padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin: 20px auto 0 auto;
    .footer-link{
      width: 1200px;
      height: 60px;
      background: rgba(255,255,255,0.1);
      border-radius: 5px;
      color: #fff;
      margin-bottom: 30px;
      .footer-link-title{
        // width: 150px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        padding: 0 20px;
        .image{
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
      .footer-link-item{
        a{
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          margin-right: 60px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .footer-center{
      height: 100px;
      .footer-right-info{
        .footer-img{
          width: 100px;
          height: 100px;
          background: rgba(255,255,255,0.7);
          border-radius: 8px;
          .footerimg{
            width: 100%;
            height: 100%;
          }
          &:nth-child(2){
            margin-left: 30px;
          }
        }
      }
      .footer-left-info{
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 22px 0;
        .footer-top{
          display: flex;
          height: 50px;
          .law{
            a{
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 18px;
            }
          }
          .companyInfo{
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            margin-right: 69px;
          }
          .linkList{

            span{
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
            a{
              margin-right: 45px;
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
        .footer-bottom{
          height: 50px;
          .footer-banben{
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            margin-right: 100px;
            a{
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  .portal-footer-in{
    width: 100%;
    // height: 240px;
    background: linear-gradient(90deg, #3B4357 0%, #546884 100%);
    box-shadow: inset 0px 1px 0px 0px hsl(214, 23%, 60%);
    // .navigation-bar-footer{
    //   background: linear-gradient(90deg, #3B4357 0%, #546884 100%);
    //   box-shadow: inset 0px 1px 0px 0px #8195B0;
    // }
    &.light{
      background: #09284E;
      box-shadow: inset 0px 1px 0px 0px #D7E3F1;
      .navigation-bar-footer{
        background: #09284E;
        box-shadow: inset 0px 1px 0px 0px #D7E3F1;
      }
    }
  }
  @media screen and (max-width:1200px){
    .navigation-bar-footer{
      width: 60rem;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      box-sizing: border-box;
      margin: 0 auto;
      .footer-link{
        width: 60rem;
        height: 3rem;
        background: rgba(255,255,255,0.1);
        border-radius: .25rem;
        color: #fff;
        margin-bottom: 1.5rem;
        .footer-link-title{
          // width: 7.5rem;
          display: flex;
          justify-content: center;
          font-size: .9rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          padding: 0 1rem;
          .image{
            width: .8rem;
            height: .8rem;
            margin-right: .2rem;
          }
        }
        .footer-link-item{
          a{
            font-size: .9rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin-right: 3rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .footer-center{
        height: 5rem;
        .footer-right-info{
          .footer-img{
            width: 5rem;
            height: 5rem;
            background: rgba(255,255,255,0.7);
            border-radius: .4rem;
            .footerimg{
              width: 5rem;
              height: 5rem;
            }
            &:nth-child(2){
              margin-left: 1.5rem;
            }
          }
        }
        .footer-left-info{
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // padding: 22px 0;
          .footer-top{
            display: flex;
            height: 2.5rem;
            .law{
              a{
                font-size: .75rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: .9rem;
              }
            }
            .companyInfo{
              font-size: .75rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: .9rem;
              margin-right: 3.45rem;
            }
            .linkList{

              span{
                font-size: .75rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
              }
              a{
                margin-right: 2.25rem;
                font-size: .75rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }
          .footer-bottom{
            height: 2.5rem;
            .footer-banben{
              font-size: .75rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              margin-right: 5rem;
              a{
                font-size: .75rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
</style>