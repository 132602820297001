<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar">
    </div>
    <div ref="editor" class="text">
    </div>
    <div class="e-hint" v-if="hint">最多输入1万字!</div>
  </div>
</template>
<script>
import E from 'wangeditor'
import ossService from "@/utils/oss";
import global from "@/utils/global";
export default {
  name: 'editoritem',
  data () {
    return {
      // uploadPath,
      editor: null,
      info_: null,
      hint:false
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    },
    total:{
      type: Number,
      default:10000
    }
  },
  watch: {
    isClear (val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info_ = null
      }
    },
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    }
    // value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted () {
    this.seteditor()
    this.editor.txt.html(this.value)
  },
  methods: {
    seteditor () {
      // http://192.168.2.125:8080/admin/storage/create
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      console.log(this.editor)
      this.editor.config.uploadImgShowBase64 = true // base 64 存储图片
      this.editor.config.uploadImgServer = 'http://otp.cdinfotech.top/file/upload_images'// 配置服务器端地址
      this.editor.config.uploadImgHeaders = {}// 自定义 header
      this.editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 10 * 1024 * 1024 // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 6 // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
      this.editor.config.pasteFilterStyle = false
      // 配置菜单
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'image', // 插入图片
        'table', // 表格
        // 'video', // 插入视频
        // 'emoticon', //表情
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ];

      // this.editor.config.onchange = (html) => {
      //   let Text = this.editor.txt.text();
      //   if(Text.length>10000){
      //    // this.hint = true;
      //     this.$message.warning('内容描述最多输入1万字!')
      //    this.editor.txt.html(this.info_)
      //   }else{
      //     this.hint = false;
      //     this.info_ = html // 绑定当前逐渐地值
      //   }
      //   this.$emit('change', this.info_) // 将内容同步到父组件中
      // }
      this.editor.config.onchange = (html) => {
        let Text = this.editor.txt.text();
        if(Text.length>this.total){
          // this.hint = true;
          this.$message.warning('内容描述最多输入' + this.total + '个字!')
          let str = Text.substring(0, this.total)
          this.info_ = str
          // this.editor.txt.html(Text)
        }else{
          this.hint = false;
          this.info_ = html // 绑定当前逐渐地值
        }
        this.$emit('change', this.info_) // 将内容同步到父组件中
      }
      this.editor.config.customUploadImg = function(resultFiles, insertImgFn){
        const oss = new ossService();
        let file = resultFiles && resultFiles[0] // 文件信息
        if (!file) {
          return false
        }
        let filename = file.name
        let uuid = oss.getFileNameUUID();
        oss.put({uploadFileType:40},uuid+filename, file).then(res=>{
           insertImgFn(oss.urlTransform(res.url,global.getParamsKey('IMG_URL')));
        })
      }
      // 创建富文本编辑器
      this.editor.create()
    }
  }
}
</script>
<style lang="css">
  .editor {
    width: 100%;
    margin-left: 15px;
    position: relative;
    z-index: 0;
    border: 1px solid #ccc;
  }
  .editor > .text {
      height: 260px;
      overflow-y: auto;
  }
  .editor > .toolbar{
    border-bottom: 1px solid #ccc;
  }
  .editor > .w-e-icon-upload2{
    font-size: 60px;
  }
  .e-hint{
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    color: red;
  }
  .text ul li{ list-style:disc inside;}
  .text ol li{ list-style:decimal inside;}
</style>
