<template>
    <div class="preserve-inform">
        <div class="preserve-inform-1" v-if="$store.state.systemMaintain.type === 1 && $store.state.preserveType === 1">
            <div class="pi-dialog__wrapper"></div>
            <div class="pi-dialog-container">
                <div class="pi-d-img">
                    <img :src="imgObj.img1" alt="" class="pi-img-1">
                    <i class="el-icon-circle-close" @click="$store.commit('setPreserveType',2)"></i>
                </div>
                <div class="pi-d-content">
                    <div class="pi-d-c-title" v-if="false">维护通知</div>
                    <div class="pi-d-c-text" v-html="$store.state.systemMaintain.content">
                        平台维护通知：尊敬的用户，平台将于平台维护通知：尊敬的用户，平台将于时段进行系统升级维护，届时将关闭平台，给平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平台将于平平台维护通知：尊敬的用户，平
                    </div>
                </div>
            </div>
        </div>
        <div class="preserve-inform-2"  @click="$store.commit('setPreserveType',1)" v-if="$store.state.systemMaintain.type === 1 && $store.state.preserveType === 2">
            <img :src="imgObj.img2" alt=""  class="pi-img-2">
            <i class="el-icon-circle-close" @click.stop="$store.commit('setPreserveType',3)"></i>
            <span class="pi2-text">维护通知</span>
        </div>
    </div>
</template>

<script>
    import projectStatus from '@/utils/mixins/projectStatus';
    export default {
        name: "preserveInform",
        mixins:[projectStatus],
        data(){
            return {
                imgObj:{
                    img1:require('@/assets/preserve-inform.png'),
                    img2:require('@/assets/preserve-inform-2.png')
                },
                setTime:''
            }
        },
        created() {
            let systemMaintain = this.getCookies('systemMaintain'),
                isShowType = this.getCookies('isShowType');
            if(!systemMaintain){
                // this.systemNoticeGet();
            }else{
                let obj = JSON.parse(systemMaintain);
                this.$store.commit('setSystemMaintain', obj);
                if(!isShowType){
                    this.$store.commit('setPreserveType',1);
                }else{
                    this.$store.commit('setPreserveType',isShowType);
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.preserve-inform{
    .preserve-inform-1{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        .pi-dialog__wrapper{
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background: #000000;
            z-index: 2006;
        }
        .pi-dialog-container{
            position: relative;
            width: 416px;
            min-height: 200px;
            background: #FFFFFF;
            border-radius: 20px;
            z-index: 2008;
            left: calc(50% - 208px);
            top: calc(50% - 100px);
            .pi-d-img{
                position: absolute;
                width: 100%;
                height: 136px;
                top: -110px;
                left: 0;
                .pi-img-1{
                    width: 100%;
                    height: 100%;
                    background-size: 100%;
                }
                >i{
                    position: absolute;
                    top: 20px;
                    right: 0;
                    color: white;
                    font-size: 38px;
                    cursor: pointer;
                    font-weight: 100;
                }
            }
            .pi-d-content{
                width: calc(100% - 68px);
                margin: 0 auto;
                padding-top: 20px;
                padding-bottom: 20px;
                .pi-d-c-title{
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    text-align: center;
                    margin-bottom: 10px;
                }
                .pi-d-c-text{
                    min-height: 80px;
                    font-size: 16px;
                    text-align: left;
                    padding-bottom: 20px;
                    ::v-deep p{
                        font-size: 16px;
                        text-indent: 2em;
                        line-height: 32px;
                        font-weight: 500;
                        color: #666666;
                    }
                }
            }
        }
    }
    .preserve-inform-2{
        position: fixed;
        width: 186px;
        height: 80px;
        right: 28px;
        bottom: 145px;
        cursor: pointer;
        z-index: 2000;
        .pi-img-2{
            width: 100%;
            height: 100%;
            background-size: 100%;
        }
        >i{
            position: absolute;
            color: #A5A5A5;
            font-size: 20px;
            right: 0;
            top: 0;
        }
        .pi2-text{
            position: absolute;
            font-size: 20px;
            font-weight: bold;
            color: #FFFFFF;
            left: 80px;
            top: 32px;
        }
    }
}
</style>